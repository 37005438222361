import React from "react"

import Header from "../components/header"
import Footer from "../components/footer"
import Seo from "../components/seo"
import Accesibilidad from "../components/accesibilidad"

export default function NotFoundPage(data) {
  let url_global = process.env.GATSBY_API_URL

  return (
    <>
      <Accesibilidad />
      <Header site="personas" />
      <Seo title="Personas" />
      <div
        className="absolute w-screen altura_banner_blog ancho_banner_fondo_blog_sm altura_banner_quienes_somos lg:w-screen altura_banner_quienes_somos posicion_lado_banner_ventaja -top-0 bg-cover bg-right"
        title="valla_03_6e3f99d9a6.jpg"
      ></div>

      <div
        style={{ width: "fit-content" }}
        className="error_404 container mx-auto flex item-center w-fit gap-40 titulo_principal_blog_sm titulo_error text-poppins-bold lg:relative titulo_ventaja_lg ml-auto mr-auto"
      >
        <span
          className="text-poppins-bold color-texto-parrafo"
          style={{ fontSize: "6rem" }}
        >
          404<br></br>
          <p className="mitexto_color color-texto-parrafo text-poppins-bold">
            Este es un error{" "}
            <span className="mitexto_color green-secundary">404</span>
          </p>
          <p className="mitexto_color color-texto-parrafo text-poppins-regular">
            La página consultada no fue encontrada
          </p>
        </span>
        <img
          alt="pdf_download.png"
          title="pdf_download.png"
          className="altura_error fill-current w-44 h-44 mr-2"
          src={url_global + "/uploads/ico_404_504_ed6c15c6f3.webp"}
        />
      </div>

      <div className="container mx-auto" style={{ marginTop: "19rem" }}></div>

      <Footer />
    </>
  )
}
